import {
  ApplicationEntityType,
  Avatar,
  Badge,
  Text,
  CopyLinkButton,
} from '@changex/design-system'
import { FundApplication } from '../../../types'
import { utils } from '@features/funds/utils'
import { When } from 'react-if'
import { useLocation } from 'react-router-dom'

const TOOLTIP = 'Copy direct link to application'

type TProps = {
  data: FundApplication
}

const ApplicationOwner: React.FC<TProps> = ({ data }) => {
  const { pathname } = useLocation()
  const applicationUrl = `${window.location.origin}${pathname}/${data.id}`

  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <div className="flex flex-1">
      <div className="mr-4">
        <Avatar sourceUrl={data.owner?.avatarUrl || ''} />
      </div>
      <div className="flex flex-col">
        <div className="mb-1 flex items-center gap-1.5">
          <Text size="lg">{data.owner?.fullName}</Text>
          {data?.entityType && (
            <ApplicationEntityType entityType={data.entityType} />
          )}
          {utils.isOpenGrantApplication(data) ? (
            <div>
              <Badge variant="open_grant">Open grant</Badge>
            </div>
          ) : null}
          <div onClick={handleWrapperClick}>
            <CopyLinkButton
              label={TOOLTIP}
              link={applicationUrl}
              tooltipHint={TOOLTIP}
            ></CopyLinkButton>
          </div>
        </div>
        {data.organisationName ? (
          <Text variant="light" size="sm">
            {data.organisationName}
          </Text>
        ) : null}
        <Text variant="light" size="sm">
          {data.owner?.email}
        </Text>
        <When condition={data.owner?.phone}>
          <Text className="mt-6 flex" variant="light" size="sm">
            {utils.isOpenGrantApplication(data)
              ? data?.phone
              : data.owner?.phone}
          </Text>
        </When>
        <When condition={data.location?.address}>
          <Text className="mt-2" variant="light" size="sm">
            {data.location?.address}
          </Text>
        </When>
      </div>
    </div>
  )
}

export default ApplicationOwner
