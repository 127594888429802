import { Heading, Pagination, Text, WithTooltip } from '@changex/design-system'
import Layout from 'app/funds/[id]/layout'
import StatusLegend from 'features/funds/components/status-legend'
import { ApplicationsTabs } from 'features/funds/components/applications-tabs'
import { useApplicationsQueryParams } from '@features/funds/hooks/use-applications-query-params'
import {
  APPLICATIONS_PAGE_SIZE,
  APPLICATIONS_TAB_LIST,
} from '@features/funds/constants'
import { useApplicationsListCount } from '@features/funds/hooks/use-applications-list-count'
import { Dropdown } from '@changex/design-system'
import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useApplicationDownload } from '@features/funds/hooks/use-application-download'
import { useFund } from 'shared/providers'
import { useApplicationList } from '@features/funds/hooks/use-application-list'
import { sum } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

const ERROR_DOWNLOADING_MESSAGE = 'There was an error requesting the download'

const FundApplicationsContent = () => {
  const fund = useFund()
  const totalApplications = fund.aggregates
    ? sum(Object.values(fund?.aggregates))
    : 0
  const navigate = useNavigate()

  const { applicationId } = useParams<{ applicationId: string }>()

  const applicationDownloadMutation = useApplicationDownload()

  const handleDownloadAll = useCallback(() => {
    applicationDownloadMutation.mutate(
      {
        allApplications: true,
        fundId: fund.id,
      },
      {
        onSuccess: () => {
          toast.success(
            'An export of applicants will be emailed to you in the next few minutes.',
            { duration: 5000 }
          )
        },
        onError: () => {
          toast.error(ERROR_DOWNLOADING_MESSAGE, { duration: 5000 })
        },
      }
    )
  }, [applicationDownloadMutation, fund])

  const handleDownloadOpenGrant = useCallback(() => {
    applicationDownloadMutation.mutate(
      { fundId: fund.id },
      {
        onSuccess: () => {
          toast.success(
            'An export of open grant applicants will be emailed to you in the next few minutes.',
            { duration: 5000 }
          )
        },
        onError: () => {
          toast.error(ERROR_DOWNLOADING_MESSAGE, { duration: 5000 })
        },
      }
    )
  }, [applicationDownloadMutation, fund])

  useEffect(() => {
    if (fund && !applicationId) {
      const tabs = APPLICATIONS_TAB_LIST(fund.options.type).map(
        (tab) => tab?.key
      )
      const tabId = tabs.findIndex((tab) =>
        fund.aggregates?.hasOwnProperty(tab)
      )

      if (tabId > 0) {
        navigate(`/funds/${fund.id}/applications?tab=${tabId}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applications = useApplicationList()

  useEffect(() => {
    if (applicationId && applications.data) {
      const tabs = APPLICATIONS_TAB_LIST(fund.options.type).map(
        (tab) => tab?.statusId
      )
      const tabId = tabs.findIndex(
        (tab) => tab === applications.data?.results[0].applicationStatus
      )

      navigate(`/funds/${fund.id}/applications/${applicationId}?tab=${tabId}`)
    }
  }, [applicationId, applications.data, fund.id, fund.options.type, navigate])

  const resultCount = useApplicationsListCount() ?? 0

  const [applicationsQueryParams, setApplicationsQueryParams] =
    useApplicationsQueryParams()

  // TODO: improve type (needs to be updated in the design system)
  const handleFilter = useCallback(
    (filter: any) => {
      setApplicationsQueryParams({
        page: filter.page,
      })
    },
    [setApplicationsQueryParams]
  )

  if (
    !applications.isLoading &&
    !applications.isFetching &&
    totalApplications === 0
  ) {
    return (
      <div className="max-w-changex mx-auto my-20 text-center">
        <Text size="2xl">No applicants yet</Text>
        <p className="mt-4">Come back soon to see new applications.</p>
      </div>
    )
  }

  return (
    <div>
      <div className="bg-copper-50">
        <div className="max-w-changex mx-auto py-2.5">
          <div className="flex justify-between">
            <Heading className="flex items-center">
              Applications
              <WithTooltip tooltip="No. of applications in the open grants inbox, replication inbox, allocated, succeeded, approved, paid seed, impact, paid impact, failed, failed impact, rejected, and refunded state.">
                <span className="ml-3 border-b border-dashed border-gray-300 text-lg font-light text-gray-400">
                  ({totalApplications})
                </span>
              </WithTooltip>
            </Heading>
            <div className="flex">
              <StatusLegend />
              <Dropdown
                title="Download"
                disabled={applicationDownloadMutation.isLoading}
                items={[
                  {
                    type: 'button',
                    id: 'all',
                    title: 'Download all applicants',
                    onClick: handleDownloadAll,
                  },
                  {
                    type: 'button',
                    id: 'open-grant',
                    title: 'Download open grant applicants',
                    onClick: handleDownloadOpenGrant,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <ApplicationsTabs
        aggregates={fund.aggregates!}
        fundType={fund.options.type}
      />
      {resultCount > APPLICATIONS_PAGE_SIZE ? (
        <div className="mt-4">
          <Pagination
            onFiltering={handleFilter}
            currentPage={applicationsQueryParams.page ?? 1}
            ariaLabel="Applications paging"
            meta={applications.data?.meta}
            resultsPerPage={APPLICATIONS_PAGE_SIZE}
          />
        </div>
      ) : null}
    </div>
  )
}

export default function FundApplicationsPage() {
  return (
    <Layout>
      <FundApplicationsContent />
    </Layout>
  )
}
